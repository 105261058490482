import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer text-sm text-right">
      <div className='footer-left w-4/6 pr-5'>
        Built with ❤️ in India by {" "}
        <a href="https://www.radicalloop.com/" rel="noreferrer" target="_blank">
          RadicalLoop
        </a>
      </div>
      <div className='footer-right w-2/6 pr-4'>
        <Link to="/privacy-policy">
          Privacy Policy
        </Link>
        {" "} | {" "}
        <Link to="/terms-of-use">
          Terms of Use
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
