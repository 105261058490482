import React, { useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import ThemeSwitch from "../../theme-switch/ThemeSwitch";
import moment from "moment-timezone";
import classNames from "classnames";
import BookTime from "../../book-time/BookTime";
import Validations from "./Validations";
import { useDispatch } from "react-redux";
import { storeEvent } from "../../../store/schedule/action";
import { ReactComponent as GlobIcon } from "../../../assets/images/svg/globe.svg";

const BookThisEvent = ({
  setStep,
  eventData,
  setEventData,
  setMeetingData,
  currentTime,
  currentTimeZone,
}) => {
  const dispatch = useDispatch();
  const [switchActive, setSwitchActive] = useState(false);
  const [errors, setErrors] = useState({});
  const isSubmitted = useRef(false);
  const [daySlot, setDaySlot] = useState({});
  const [isEventCreating, setIsEventCreating] = useState(false);
  const [commonSlot, setCommonSlot] = useState([
    { start_time: "", end_time: "" },
  ]);

  const [unavailable, setUnavailable] = useState([]);

  const checkForErrors = (eventData, scheduleTimings, switchActive) => {
    const { errors, hasError } = Validations(
      eventData,
      scheduleTimings,
      switchActive,
      unavailable
    );
    setErrors(errors);
    return hasError;
  };

  const toggleTimingSwitch = () => {
    setSwitchActive(!switchActive);
  };
  const [datePick, setDatePick] = useState({
    startDate: null,
    endDate: new Date(""),
    key: "selection",
  });

  const onChange = (item) => {
    let selectedDays = {};
    const selectedEventStartDate = moment(item.selection.startDate);
    const selectedEventEndDate = moment(item.selection.endDate);

    setEventData({
      ...eventData,
      start_date: selectedEventStartDate.format("YYYY-MM-DD"),
      end_date: selectedEventEndDate.format("YYYY-MM-DD"),
    });

    let startDay = selectedEventStartDate.startOf("day");
    let endDay = selectedEventEndDate.startOf("day");

    while (startDay.diff(endDay) <= 0) {
      let dayName = startDay.clone().format("dddd").toLowerCase();

      if (!selectedDays[dayName]) {
        selectedDays[dayName] = [{ start_time: "", end_time: "" }];
      }
      startDay.add(1, "days");
    }
    setDaySlot(selectedDays);
    setDatePick(item.selection);

    if (errors.date) {
      setErrors({ ...errors, date: false });
    }
  };

  const onDurationChange = (e) => {
    setEventData({ ...eventData, [e.target.name]: e.target.value });
    if (errors.duration) {
      setErrors({ ...errors, duration: false });
    }
  };

  const onNext = () => {
    isSubmitted.current = true;

    const hasError = checkForErrors(
      eventData,
      switchActive ? daySlot : commonSlot,
      switchActive
    );
    if (hasError) {
      return;
    }

    if (switchActive) {
      delete eventData["common_schedule_timings"];
    } else {
      delete eventData["schedule_timings"];
    }

    if (eventData?.schedule_timings) {
      Object.entries(eventData?.schedule_timings).forEach((timing) => {
        const [key] = timing;
        let check = unavailable.includes(key);
        if (check) {
          delete eventData.schedule_timings[key];
        }
      });
    }
    eventData.timezone = moment.tz.guess();
    setIsEventCreating(true);
    dispatch(storeEvent(eventData))
      .then((res) => {
        let response = res.data.data;
        setMeetingData(response);
        setIsEventCreating(false);
        setStep(3);
      })
      .catch((err) => {
        setIsEventCreating(false);
        console.log("fail");
        //   setErrors(err.fields);
      });
  };

  return (
    <section
      className={classNames(
        { "book-this-event-screen-2": switchActive },
        "book-this-event"
      )}
    >
      <div className="card">
        <div className="card-title">When can people book this event?</div>
        <div className="date-range-picker">
          <div className="md-heading ml-1">Date Range</div>
          <DateRangePicker
            onChange={(item) => onChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[datePick]}
            direction="horizontal"
            minDate={new Date()}
          />
        </div>
        {errors.date && <span className="text-danger">{errors.date}</span>}
        {currentTime && currentTimeZone && (
          <div className="time-zone mt-6">
            <label className="font-Roboto-bold text-lg">Timezone</label>
            <span className="flex items-center mt-2">
              <GlobIcon width="20" height="20" />
              {currentTime !== "" && currentTimeZone !== "" && (
                <p className="ml-2">
                  {currentTimeZone + " (" + currentTime + ")"}
                </p>
              )}
            </span>
          </div>
        )}
        <form>
          <div className="form-group">
            <label className="font-Roboto-bold">Durations</label>
            <div className="form-select w-56">
              <select
                name="duration"
                value={eventData.duration}
                onChange={onDurationChange}
              >
                <option value="">Select Duration</option>
                <option value="15">15 min</option>
                <option value="30">30 min</option>
                <option value="45">45 min</option>
                <option value="60">60 min</option>
              </select>
            </div>
            {errors.duration && (
              <span className="text-danger">{errors.duration}</span>
            )}
          </div>

          <div className="md-heading sm:mt-8">
            How do you want to offer your availability for this event type?
          </div>
          <div className="md:flex gap-4 mt-6 mb-10 book-this-event__set-time">
            <p
              className={classNames(
                switchActive ? "text-darksilver" : "text-vividtangelo"
              )}
            >
              Set same time for each day
            </p>
            <div className="relative set-time-switch">
              <ThemeSwitch onChange={toggleTimingSwitch} />
            </div>
            <p
              className={classNames(
                switchActive ? "text-vividtangelo" : "text-darksilver",
                "md:ml-3"
              )}
            >
              Set custom time for each day
            </p>
          </div>
          <BookTime
            switchActive={switchActive}
            daySlot={daySlot}
            commonSlot={commonSlot}
            setCommonSlot={setCommonSlot}
            setDaySlot={setDaySlot}
            eventData={eventData}
            setEventData={setEventData}
            setErrors={setErrors}
            errors={errors}
            setUnavailable={setUnavailable}
            unavailable={unavailable}
          />
          <div className="flex justify-end md:mt-16 mt-10 btn-group">
            <button
              onClick={() => setStep(1)}
              type="button"
              className="btn mr-4"
            >
              Back
            </button>
            <button
              onClick={() => onNext()}
              type="button"
              className="btn btn-dark"
              disabled={isEventCreating}
            >
              Next
              {isEventCreating && <div className="loader"></div>}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default BookThisEvent;
