import { omit } from "lodash";
import api from "../../api";
import { getErrors } from "../../shared/utils/helpers";

export function storeEvent(data) {
  return () => {
    return new Promise((resolve, reject) => {
      api
        .post("/event/store", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(getErrors(err));
        });
    });
  };
}

export function getEvent(slug) {
  return () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/event/${slug}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(getErrors(err));
        });
    });
  };
}

export function getTimings(data) {
  return () => {
    return new Promise((resolve, reject) => {
      api
        .post("/event/get-timings", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(getErrors(err));
        });
    });
  };
}

export function storeBooking(id, data) {
  return () => {
    return new Promise((resolve, reject) => {
      api
        .post(`/event/store/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(getErrors(err));
        });
    });
  };
}

export function scheduleMeeting(data) {
  return () => {
    return new Promise((resolve, reject) => {
      api
        .post("event/client/store", omit(data, "organizer_lastName"))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(getErrors(err));
        });
    });
  };
}

export function generateMeetLink(data) {
  return () => {
    return new Promise((resolve, reject) => {
      api
        .post("event/client/generate/link", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(getErrors(err));
        });
    });
  };
}

export function getClientMeetEvent(hash) {
  return () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/event/client/${hash}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(getErrors(err));
        });
    });
  };
}