import React from "react";
import logo from "../../assets/images/success.png";
import error from "../../assets/images/error.png";
import back from "../../assets/images/back.png";

import calender from "../../assets/images/svg/calendar-icon-orange.svg";
import clock from "../../assets/images/svg/clock-icon.svg";

import clsx from "clsx";
import { get } from "lodash";
import moment from "moment";

const ErrorSuccessModal = ({ setStep, clientMeetData, isError }) => {
  function formatDate(inputDate) {
    const formattedDate = moment(inputDate).format("DD MMMM  YYYY");
    return formattedDate;
  }
  return (
    <div className="modal">
      {isError ? (
        <img src={error} className="logo_image_success  mx-auto " alt="logo" />
      ) : (
        <img src={logo} className="logo_image_success  mx-auto " alt="logo" />
      )}
      <p className={clsx("text-center text-2xl font-bold mb-6 leading-6")}>
        {isError
          ? "Something went wrong.. "
          : "Meeting scheduled successfully."}
      </p>
      {isError ? (
        <div>
          <p className=" font-normal text-sm leading-6 text-center maxSm:text-center ">
            We apologize for the inconvenience. Please try again.
          </p>
        </div>
      ) : (
        <div>
          <p className=" font-normal text-sm leading-6 maxSm:text-center">
            We appreciate you reaching out to RadicalLoop. Rest assured, we'll
            promptly provide you with a confirmation shortly.
          </p>

          <p className=" font-semibold text-sm leading-6 mb-2">
            Your selected time:
          </p>

          <div className="schedule_date flex gap-10 items-center maxSm:flex-col maxSm:gap-4 maxSm:jutify-start">
            <div className="flex justify-center items-center gap-3">
              <img src={calender} className=" w-6 h-6   " alt="logo" />
              <span className=" text-center text-sm  date_text">
                {`${formatDate(clientMeetData.schedule_date)} `}
              </span>
            </div>

            <div className="flex justify-center items-center gap-3">
              <img src={clock} className="  w-6 h-6   " alt="logo" />

              <span className=" text-center text-sm  date_text">
                {get(clientMeetData.schedule_timings, "start_time")}
              </span>
            </div>
          </div>
        </div>
      )}

      <button className="mx-auto block" onClick={() => setStep(1)}>
        <img src={back} className="back-icon mr-1" alt="logo" />
        Back
      </button>
    </div>
  );
};

export default ErrorSuccessModal;
