import { set } from "lodash";

export default function Validations(
  eventData,
  scheduleTimings,
  customTimings,
  unavailableDays
) {
  let hasError = false;
  const errors = {};

  if (scheduleTimings.length === 0) {
    errors["timing"] = true;
    hasError = true;
  } else {
    let timings = customTimings
      ? Object.keys(scheduleTimings)
      : scheduleTimings;

    timings.map((val, index) => {
      if (customTimings) {
        scheduleTimings[val].map((dayValue, dayIndex) => {
          if (unavailableDays.includes(val)) return true;
          hasError = checkAndSetTimeError(
            dayValue,
            `${val}.${dayIndex}.time`,
            errors,
            hasError
          );
          return true;
        });
      } else {
        hasError = checkAndSetTimeError(val, `${index}.time`, errors, hasError);
      }
      return true;
    });
  }

  if (!eventData.duration) {
    errors["duration"] = "Please select duration.";
    hasError = true;
  }

  if (!eventData.start_date || !eventData.end_date) {
    errors["date"] = "Please select date.";
    hasError = true;
  }

  return { hasError, errors };
}

const checkAndSetTimeError = (value, objKey, errors, hasError) => {
  let obj = {};
  const message =
    !value.start_time || !value.end_time
      ? "Please select all fields."
      : value.start_time > value.end_time
      ? "Start time should be less than end time."
      : false;

  set(obj, objKey, message);
  set(obj, objKey, message);

  errors["schedule_timings"] = {
    ...errors["schedule_timings"],
    ...obj,
  };

  return hasError
    ? hasError
    : !value.start_time || !value.end_time || value.start_time > value.end_time;
};
