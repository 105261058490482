import React from "react";
import logo from "../../assets/images/svg/calendar-icon-large.svg";
import Calendar from "react-calendar";

const DateCalender = ({
  setClientMeetData,
  clientMeetData,
  width,
  formData,
  setFormData,
}) => {
  const onChangeCalendarDate = (selectedDate) => {
    if (width <= 639) {
      setFormData((prev) => ({
        ...prev,
        formStep: 2,
      }));
    }

    setClientMeetData((prevState) => ({
      ...prevState,
      schedule_date: selectedDate,
    }));
  };

  return (
    <div className="center_left maxSm:w-full ">
      <img src={logo} className="logo_image" alt="logo" />
      <i className="text-white text-lg">Choose Meeting Date</i>
      <div className="datepicker">
        <Calendar
          onChange={onChangeCalendarDate}
          value={clientMeetData.schedule_date}
          minDate={formData.timeZoneStartDate}
        />
      </div>
    </div>
  );
};

export default DateCalender;
