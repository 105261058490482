import React, { useState, useRef } from "react";
import Validations from "./Validations";
import classNames from "classnames";
import Select, { components } from "react-select";
import { icons, options } from "../../../shared/utils/helpers";

const WhatEventIsThat = ({
  eventData,
  setEventData,
  setStep,
  setIcon,
  icon,
}) => {
  const [errors, setErrors] = useState({});

  const isSubmitted = useRef(false);

  const { Option } = components;
  const LocationOption = (props) => (
    <Option {...props}>
      <div className="flex items-center">
        {props.data.icon && (
          <img
            src={props.data.icon}
            style={{ width: 24 }}
            alt={props.data.label}
          />
        )}
        <p className="ml-4">{props.data.label}</p>
      </div>
    </Option>
  );
  const checkForErrors = (data) => {
    const { errors, hasError } = Validations(data);
    setErrors(errors);
    return hasError;
  };

  const onNext = (e) => {
    e.preventDefault();

    isSubmitted.current = true;

    const hasError = checkForErrors(eventData);

    if (!hasError) {
      setStep(2);
    }
  };
  const onChange = (e) => {
    const updatedEventData = {
      ...eventData,
      [e.target.name]: e.target.value,
    };

    setEventData(updatedEventData);

    if (isSubmitted.current) {
      checkForErrors(updatedEventData);
    }
  };
  const onPlateformChange = (e) => {
    const updatedEventData = { ...eventData, location: e.value };
    setEventData(updatedEventData);
    setIcon(e.label);

    if (isSubmitted.current) {
      checkForErrors(updatedEventData);
    }
  };
  return (
    <section className="what-event-is-that">
      {" "}
      <div className="card">
        <div className="card-title">What event is that?</div>
        <form onSubmit={onNext}>
          <div className="grid sm:grid-cols-2 sm:gap-3 mt-4">
            <div className="form-group">
              <label>
                Your name<span>*</span>
              </label>
              <input
                className={classNames(
                  {
                    "is-invalid": errors.organizer_name,
                  },
                  "form-control"
                )}
                name="organizer_name"
                value={eventData.organizer_name}
                onChange={onChange}
              />
              {errors.organizer_name && (
                <div className="text-danger">{errors.organizer_name}</div>
              )}
            </div>
            <div className="form-group">
              <label>
                Your email<span>*</span>
              </label>
              <input
                className={classNames(
                  {
                    "is-invalid": errors.organizer_email,
                  },
                  "form-control"
                )}
                name="organizer_email"
                value={eventData.organizer_email}
                onChange={onChange}
              />
              {errors.organizer_email && (
                <div className="text-danger">{errors.organizer_email}</div>
              )}
            </div>
          </div>
          <div className="grid sm:grid-cols-2 sm:gap-3">
            <div className="form-group">
              <label>
                Event name<span>*</span>
              </label>
              <input
                className={classNames(
                  {
                    "is-invalid": errors.name,
                  },
                  "form-control"
                )}
                name="name"
                value={eventData.name}
                onChange={onChange}
              />
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </div>
            <div className="form-group">
              <label>
                Location<span>*</span>
              </label>
              <div className="form-select">
                <Select
                  className={classNames({
                    "is-invalid": errors.location,
                  })}
                  placeholder="Select Location"
                  options={options}
                  components={{ Option: LocationOption }}
                  onChange={onPlateformChange}
                  isSearchable={false}
                  getOptionLabel={(e) => (
                    <div className="flex items-center">
                      {e.icon && (
                        <img src={e.icon} style={{ width: 24 }} alt={e.label} />
                      )}
                      <p className="ml-3">{e.label}</p>
                    </div>
                  )}
                  value={
                    eventData.location && {
                      value: eventData.location,
                      label: eventData.location,
                      icon: icons[icon],
                    }
                  }
                />
              </div>
              {errors.location && (
                <div className="text-danger">{errors.location}</div>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>
              Description/Instructions<span>*</span>
            </label>
            <textarea
              rows="5"
              className={classNames(
                {
                  "is-invalid": errors.description,
                },
                "form-control"
              )}
              name="description"
              value={eventData.description}
              onChange={onChange}
            />
            {errors.description && (
              <div className="text-danger">{errors.description}</div>
            )}
          </div>
          <div className="form-group items-end sm:mt-16 mt-10">
            <button type="submit" className="btn btn-dark">
              Next
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default WhatEventIsThat;
