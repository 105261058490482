import React from "react";

import { Link } from "react-router-dom";
import Linkify from "react-linkify";
import moment from "moment";

import { ReactComponent as CalenderIconLarge } from "../../../assets/images/svg/calendar-icon-large.svg";
import { icons } from "../../../shared/utils/helpers";
import { ReactComponent as CalenderIcon } from "../../../assets/images/svg/calendar-icon-orange.svg";
import { ReactComponent as ClockIcon } from "../../../assets/images/svg/clock-icon-orange.svg";


const ConfirmationPage = ({ eventData, bookingData, currentTimeZone, alreadyScheduled=false }) => {
  return (
    <>
      <section className="confirmation-page">
        <div className="confirmation-page__header">
          <div className="flex">
            <CalenderIconLarge
              width="60"
              height="60"
              className="confirmation__calendar mt-1"
            />
            <div className="ml-4 lg:ml-8">
              {!alreadyScheduled && (
                <>
                  <div className="confirmation-page__title">All Good!</div>
                  <div className="confirmation-page__subtitle">
                    You are scheduled with {eventData.organizer_name}
                  </div>
                </>
              )}

              {alreadyScheduled && (
                <>
                  <div className="confirmation-page__title">Meeting Already scheduled 🎉</div>
                  <div>
                    to create a new meeting {" "}
                    <Link style={{borderBottom: '1px solid #f3742f', color: '#f3742f', cursor: 'pointer'}} to="/">
                        Click here
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="confirmation-page__meeting">
          <div className="flex maxMd:justify-between minMaxSm:flex-col minMaxSm:items-start">
            <div className="flex flex-col">
              <label>Event</label>
              <h3>{eventData.name}</h3>
            </div>
            <div className="confirmation-page__meeting-info">
              <label>Meet</label>
              <p>{eventData.organizer_name}</p>
              <span className="flex items-center">
                <p className="text-gray-spanish mr-3">on</p>
                {icons[eventData.location] && (
                  <img
                    src={icons[eventData.location]}
                    alt={`${eventData.location}-icon`}
                    width={32}
                    className="mr-3"
                  />
                )}
                {eventData.location}
              </span>
            </div>
          </div>
          <div className="confirmation-page__meeting-description">
            <label>Description</label>
            <Linkify properties={{ target: "_blank" }}>
              <h3>{eventData.description}</h3>
            </Linkify>
          </div>
        </div>
        <div className="confirmation-page__details">
          <ul className="confirmation__list">
            <li>
              <CalenderIcon />
              <div className="flex flex-wrap maxSm:gap-3 w-11/12 sm:gap-5">
                <p>
                  {moment(bookingData.booked_at).format("hh:mm A") +
                    " - " +
                    moment(bookingData.booked_at).format("dddd").toString() +
                    ","}
                </p>
                <p>{moment(bookingData.booked_at).format("DD MMMM YYYY")}</p>
                <p>({currentTimeZone})</p>
              </div>
            </li>
            <li>
              <ClockIcon />
              <p>{eventData.duration + " min "}</p>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
export default ConfirmationPage;
