import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import arrowIcon from "../../../assets/images/arrow.png";
import { ReactComponent as CopyIcon } from "../../../assets/images/svg/copy-icon.svg";
import { ReactComponent as CalenderIcon } from "../../../assets/images/svg/calender-icon.svg";
import { ReactComponent as TimeIcon } from "../../../assets/images/svg/time-icon.svg";
import { ReactComponent as CalenderIconLarge } from "../../../assets/images/svg/calendar-icon-large.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/svg/info-icon.svg";
import { ReactComponent as DurationIcon } from "../../../assets/images/svg/duration-icon.svg";
import { ReactComponent as GlobIcon } from "../../../assets/images/svg/globe.svg";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { icons } from "../../../shared/utils/helpers";
import Linkify from "react-linkify";

const Confirmation = ({ setStep, eventData, meetingData, icon }) => {
  const [switchActive, setSwitchActive] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isSingleDay, setIsSingleDay] = useState(false);

  const { event_schedules, url, name, duration, location, description } =
    meetingData;
  const commonTimings = JSON.parse(event_schedules[0].schedule_timings);
  const scheduleTimings = eventData.schedule_timings;
  const weekArray = moment.weekdays();

  //create start/end day/date
  const startDay = moment(event_schedules[0].schedule_date).format("dddd").toString();
  const endDay = moment(event_schedules[event_schedules.length - 1].schedule_date).format("dddd").toString();
  const startDate = moment(event_schedules[0].schedule_date).format("DD MMM YYYY").toString();
  const endDate = moment(event_schedules[event_schedules.length - 1].schedule_date).format("DD MMM YYYY").toString();
    
  useEffect(()=>{
    if(event_schedules[0].schedule_date === event_schedules[event_schedules.length - 1].schedule_date)
    {
      setIsSingleDay(true);
    }
  },[event_schedules])

  useEffect(() => {
    let checktimings = eventData.hasOwnProperty("common_schedule_timings");
    if (!checktimings) {
      setSwitchActive(true);
      return;
    }
  }, [eventData]);

  const copyUrl = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 500);
  };

  return (
    <section className="confirmation">
      <div className="confirmation__bottom-shape"></div>
      <div className="card">
        <div className="flex">
          <CalenderIconLarge
            width="50"
            height="50"
            className="confirmation__calendar"
          />
          <div className="card-title ml-4">
            Confirmation
            <p>Meeting details</p>
          </div>
        </div>
        <div className="confirmation__copy-link flex">
          <div className="form-control relative">
            <GlobIcon width="20" height="20" className="mr-2" />
            {isClicked && (
              <div className="tooltip">
                <span className="tooltiptext">Copied</span>
              </div>
            )}
            <span> {url} </span>
            <CopyToClipboard text={url}>
              <button
                className="ml-3 hover:text-black-dark"
                onClick={copyUrl}
                disabled={isClicked}
              >
                <CopyIcon />
              </button>
            </CopyToClipboard>
          </div>
          <button className="btn btn-dark" style={{ display: "none" }}>
            <img src={arrowIcon} alt="arrow-icon" />
          </button>
        </div>
        <div className="text-xl mb-2 font-bold">{name}</div>
        <p className="flex items-center">
          <span className="text-gray-spanish mr-3">on</span>
          {icons[icon] && (
            <img
              className="mr-2"
              width={24}
              src={icons[icon]}
              alt={`${icon}-icon`}
            />
          )}
          {location}
        </p>

        <ul className="confirmation__list">
          <li>
            <InfoIcon />

            <Linkify>
              <p>{description}</p>
            </Linkify>
          </li>
          <li>
            <DurationIcon />
            <p>{duration} min</p>
          </li>
          <li>
            <CalenderIcon />
            <div className="sm:flex sm:gap-5">
              {!isSingleDay && (
                <>
                  <p>
                    {startDay} <span className="text-gray-spanish">to</span>{" "}
                    {endDay}
                  </p>
                  |
                  <p>
                    {startDate} <span className="text-gray-spanish">to</span>{" "}
                    {endDate}
                  </p>
                </>
              )}
              {isSingleDay && (
                <>
                  <p>
                    {startDay}
                  </p>
                  |
                  <p>
                    {startDate}
                  </p>
                </>
              )}
            </div>
          </li>

          {!switchActive && (
            <div>
              {commonTimings?.map((val, index) => (
                <li key={index}>
                  <TimeIcon />
                  <span>
                    {moment(val.start_time, ["HH:mm"]).format("hh:mm a")} -{" "}
                    {moment(val.end_time, ["HH:mm"]).format("hh:mm a")}
                  </span>
                </li>
              ))}
            </div>
          )}

          {switchActive && (
            <div>
              {weekArray.map((day, index) => (
                <ul key={index}>
                  {Object.keys(scheduleTimings).includes(day.toLowerCase()) &&
                    scheduleTimings[day.toLowerCase()].map((val, index) => (
                      <Fragment key={index}>
                        <ul>
                          <li>
                            <span>
                              <strong>{index === 0 && day}</strong>
                            </span>
                            <span>
                              {moment(val.start_time, ["HH:mm"]).format(
                                "hh:mm a"
                              )}{" "}
                              -{" "}
                              {moment(val.end_time, ["HH:mm"]).format(
                                "hh:mm a"
                              )}
                            </span>
                          </li>
                        </ul>
                      </Fragment>
                    ))}
                </ul>
              ))}
            </div>
          )}
        </ul>
      </div>
    </section>
  );
};

export default Confirmation;
