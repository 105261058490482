import { isEmpty } from "lodash";

export default function Validations(data) {
  let hasError = false;
  const errors = {};
  const emailAddressRegex = /\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  if (isEmpty(data.name.trim())) {
    errors["name"] = "Event name is required";
    hasError = true;
  }

  if (
    isEmpty(data.organizer_email.trim()) ||
    !data.organizer_email.match(emailAddressRegex)
  ) {
    errors["organizer_email"] = "Please enter valid email";
    hasError = true;
  }

  if (isEmpty(data.organizer_name.trim())) {
    errors["organizer_name"] = "Name is required";
    hasError = true;
  }

  if (isEmpty(data.location)) {
    errors["location"] = "Select location";
    hasError = true;
  }

  if (isEmpty(data.description.trim())) {
    errors["description"] = "Description is required";
    hasError = true;
  }
  return { hasError, errors };
}
