import { isEmpty } from 'lodash';

export default function Validations(data) {
  let hasError = false;
  const errors = {};
  const emailAddressRegex = /\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  if (!data.name || isEmpty(data?.name.trim())) {
    errors.name = 'Name is required';
    hasError = true;
  }

  if (
    !data.email ||
    isEmpty(data?.email.trim()) ||
    !data.email.match(emailAddressRegex)
  ) {
    errors.email = 'Please enter valid email';
    hasError = true;
  }

  return { errors, hasError };
}
