import clsx from "clsx";
import React from "react";

const ClientMeetStepNavigation = ({ step }) => {
  return (
    <div className="top_container ">
      <div className="flex navigation">
        {/* first checkpoint */}
        <span
          className={clsx("circle_left ml-8", {
            "active_btn tick-mark": step === 2 || step === 3 || step === 4,
          })}
        ></span>
        {/* travel path */}
        <div
          className={clsx(
            { active_line: step === 2 || step === 3 || step === 4 },
            "line"
          )}
        ></div>
        {/* second checkpoint */}
        <span
          className={clsx("circle_right mr-8", {
            active_line: step === 2 || step === 3 || step === 4,
            "active_btn tick-mark_second": step === 3 || step === 4,
          })}
        ></span>
      </div>
      <div className="flex  items-center w-full justify-between">
        <div className="top_left_container ">
          <p>CHOOSE TIME</p>
        </div>
        <div className="top_left_container">
          <p>YOUR INFO</p>
        </div>
      </div>
    </div>
  );
};

export default ClientMeetStepNavigation;
