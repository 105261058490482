import React, { useEffect } from "react";
import DateCalender from "./DateCalender";
import TimeSelect from "./TimeSelect";

const ClientMeetFirstForm = ({
  setStep,
  setClientMeetData,
  clientMeetData,
  convertedTimes,
  setFormData,
  formData,
}) => {
  const width = window.innerWidth;
  useEffect(() => {
    const handleResize = () => {
      if (width <= 639) {
        setFormData((prev) => ({
          ...prev,
          formStep: 1,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          formStep: null,
        }));
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <div className="center_containerformessage ">
      <div className="center_container">
        {formData.formStep === 1 && (
          <DateCalender
            setClientMeetData={setClientMeetData}
            clientMeetData={clientMeetData}
            width={width}
            formData={formData}
            setFormData={setFormData}
          />
        )}
        {formData.formStep === 2 && (
          <TimeSelect
            setStep={setStep}
            setClientMeetData={setClientMeetData}
            clientMeetData={clientMeetData}
            convertedTimes={convertedTimes}
            setFormData={setFormData}
            formData={formData}
          />
        )}

        {formData.formStep === null && (
          <>
            <DateCalender
              setClientMeetData={setClientMeetData}
              clientMeetData={clientMeetData}
              width={width}
              formData={formData}
              setFormData={setFormData}
            />
            <TimeSelect
              setStep={setStep}
              setClientMeetData={setClientMeetData}
              clientMeetData={clientMeetData}
              convertedTimes={convertedTimes}
              setFormData={setFormData}
              formData={formData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ClientMeetFirstForm;
