export default function Validations(bookDate, bookTime) {
  let hasError = false;
  const errors = {};

  if (!bookDate) {
    errors.date = 'Please select date.';
    hasError = true;
  }

  if (!bookTime) {
    errors.time = 'Please select Time.';
    hasError = true;
  }

  return { errors, hasError };
}
