import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import Dropdown from "./Dropdown";
import moment from "moment";

const TimeSelect = ({
  setStep,
  setClientMeetData,
  clientMeetData,
  convertedTimes,
  formData,
  setFormData,
}) => {
  const [showTimeZoneDropdown, setShowTimeZoneDropdown] = useState(false);

  const dropdownRef = useRef(null);

  const onPrevClick = () => {
    setFormData((prev) => ({
      ...prev,
      formStep: 1,
    }));
  };

  const onSelectTime = (time) => {
    setClientMeetData((prev) => ({
      ...prev,
      schedule_timings: {
        start_time: time,
        end_time: moment(time, "HH:mm").add(15, "minutes").format("HH:mm"),
      },
    }));
    setStep(2);
  };

  const onChangeDuration = (e) => {
    setClientMeetData((prev) => ({
      ...prev,
      duration: e.target.value,
    }));
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowTimeZoneDropdown(false);
      }
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="center_right maxSm:w-full maxSm:p-4">
      <h2>How long do you need?</h2>
      <div className="btn_container">
        <button
          className={clsx("btn_left", {
            active_button: clientMeetData.duration === "15",
          })}
          value="15"
          onClick={onChangeDuration}
        >
          15 mins
        </button>
        <button
          className={clsx("btn_right", {
            active_button: clientMeetData.duration === "30",
          })}
          value="30"
          onClick={onChangeDuration}
        >
          30 mins
        </button>
      </div>
      <div className="dropdown">
        <div className="time_container">
          <h5>What time works best?</h5>
          <div ref={dropdownRef}>
            <div
              className="flex justify-between items-center cursor-pointer "
              onClick={() => setShowTimeZoneDropdown(!showTimeZoneDropdown)}
            >
              <h6 className=" inline">{clientMeetData.timezone}</h6>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                id="arrowdropdown"
                className="w-5 h-5"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path
                  d="M7 10l5 5 5-5H7z"
                  fill="#7c98b6"
                  className="color000000 svgShape"
                ></path>
              </svg>
            </div>

            {showTimeZoneDropdown && (
              <Dropdown
                setClientMeetData={setClientMeetData}
                setShowTimeZoneDropdown={setShowTimeZoneDropdown}
              />
            )}
          </div>
        </div>
      </div>

      <div className="time_boxes ">
        {convertedTimes.length === 0  && <h5>No slots available.</h5>}
        {convertedTimes.length > 0 && convertedTimes?.map((value, index) => (
          <div
            className="time_box maxSm:max-w-full"
            key={index}
            onClick={() => onSelectTime(value)}
          >
            {value}
          </div>
        ))}
      </div>

      <div className="btn_container_navi m-auto mt-4   ">
        {formData.formStep === 2 && (
          <button
            className={clsx("btn_left !border-r")}
            onClick={() => onPrevClick()}
          >
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default TimeSelect;
