import React from "react";
import { ReactComponent as MorningIcon } from "../../../assets/images/svg/morning-icon.svg";
import { ReactComponent as AfternoonIcon } from "../../../assets/images/svg/afternoon-icon.svg";
import { ReactComponent as EveningIcon } from "../../../assets/images/svg/evening-icon.svg";
import { ReactComponent as NightIcon } from "../../../assets/images/svg/night-icon.svg";
import moment from "moment-timezone";
import classNames from "classnames";

const BookTimeList = ({ item, timings, onTimeChange, bookTime }) => {
  const icons = {
    morning: <MorningIcon />,
    afternoon: <AfternoonIcon />,
    evening: <EveningIcon height="24" width="24" />,
    night: <NightIcon />,
  };

  return (
    <>
      <div className={`${item}-timings`}>
        <label className="flex items-center">
          <span className="mr-4">{icons[item]}</span>
          {item.toUpperCase()}
        </label>
        <div className="whitespace-no-wrap overflow-x-auto">
          {timings.map((time, index) => {
            let selectedTime = moment(bookTime, ["HH:mm:ss"]).format("hh:mm a");
            return (
              <div
                className={classNames(
                  time === selectedTime
                    ? `${item}-timings__time ${item}-timings__time-active`
                    : `${item}-timings__time `
                )}
                key={index}
                onClick={(e) => onTimeChange(e, time)}
              >
                {time}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BookTimeList;
