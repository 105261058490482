import React, { useState } from "react";
import validateFormData from "./Validations";
import { scheduleMeeting } from "../../store/schedule/action";
import { useDispatch } from "react-redux";
import { formatDate } from "../../shared/utils/helpers";
import { get } from "lodash";
import moment from "moment";
import back from "../../assets/images/back.png";


const ClientMeetSecondForm = ({
  setStep,
  setClientMeetData,
  clientMeetData,
  setIsError,
}) => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientMeetData(() => ({
      ...clientMeetData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setClientMeetData((prev) => ({
      ...prev,
      organizer_name: "",
      organizer_email: "",
      description: "",
      organizer_lastName: "",
    }));
  };

  const checkForErrors = (data) => {
    const { errors, hasError } = validateFormData(data);
    setErrors(errors);
    return hasError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasError = checkForErrors(clientMeetData);

    if (!hasError) {
      const givenDateTime = moment(clientMeetData.schedule_date);
      const givenTime = moment(
        clientMeetData.schedule_timings.start_time,
        "hh:mm A"
      );

      const currentDateTime = moment();

      if (
        givenDateTime.isSame(currentDateTime, "day") &&
        currentDateTime.isAfter(givenTime)
      ) {
        setIsError(true);
        setStep(3);
      }

      setIsLoading(true);
      dispatch(scheduleMeeting(clientMeetData))
        .then((res) => {
          resetForm();
          setIsError(false);

          setIsLoading(false);
          setStep(3);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
          setStep(3);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form_container">
        <div className="form_top">
          <h3>Your information</h3>
          <p>
            {`${formatDate(clientMeetData.schedule_date)} ${get(
              clientMeetData.schedule_timings,
              "start_time"
            )}`}
            <span className="ml-2" onClick={() => setStep(1)}>
              Edit
            </span>
          </p>

          <div className="mt-6 relative text_field">
            <div className="text_form w-full ">
              <p className="label">Name</p>
              <input
                type="text"
                name="organizer_name"
                value={clientMeetData.organizer_name}
                onChange={handleInputChange}
              />
              <span className="error">{errors.organizer_name}</span>
            </div>
          </div>
          <div className="mt-6 relative text_field">
            <div className="text_form w-full">
              <p className="label">Your email address</p>
              <input
                type="text"
                name="organizer_email"
                value={clientMeetData.organizer_email}
                onChange={handleInputChange}
              />
              <span className="error">{errors.organizer_email}</span>
            </div>
          </div>
          <div className="mt-6 text_field">
            <div className="text_form w-full relative">
              <p className="label">Description</p>
              <textarea
                className="w-full"
                name="description"
                value={clientMeetData.description}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
        </div>
        {/* <button className="mx-auto block" onClick={() => setStep(1)}>
          <img src={back} className="back-icon mr-1" alt="logo" />
          Back
        </button> */}
        <div className="bottom_navigation">
          <div className="flex items-center cursor-pointer gap-1" type="button" onClick={() => setStep(1)}>
            <img src={back} className="back-icon mr-1" alt="logo" />
            Back
          </div>
          <button
            type="submit"
            className="flex justify-center items-center gap-1 meet_form_submit"
            disabled={isLoading}
          >
            {isLoading && <div className="loader"></div>}
            Confirm
          </button>
        </div>
      </div>
    </form>
  );
};

export default ClientMeetSecondForm;
