import { useState, useEffect, useCallback } from "react";
import Header from "../header/Header";
import SubHeader from "./sub-header/SubHeader";
import ConfirmationPage from "./confirmation-page/ConfirmationPage";
import ScheduleEvent from "./schedule-event/ScheduleEvent";
import DetailPage from "./detail-page/DetailPage";
import { useParams, useNavigate } from "react-router-dom";
import { getEvent, getTimings } from "../../store/schedule/action";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import Footer from "../footer/footer";

const App = ({ currentTime, currentTimeZone }) => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [eventData, setEventData] = useState({});
  const [timings, setTimings] = useState({});
  const [scheduleTimings, setScheduleTimings] = useState({
    schedule_times: {},
    unavailable: [],
  });
  const [bookingData, setBookingData] = useState({});
  const [bookDate, setBookDate] = useState(null);

  const getEventTimings = useCallback(() => {
    const data = {
      slug: slug,
      timezone: moment.tz.guess(),
    };

    dispatch(getTimings(data))
      .then((response) => {
        setScheduleTimings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [slug, dispatch]);

  useEffect(() => {
    dispatch(getEvent(slug))
      .then((res) => {
        const response = res.data.data;
        setEventData(response);

        if (response.event_booking) {
          setBookingData(response.event_booking);
          setStep(4);
        }
      })
      .catch((err) => {
        navigate("/");
        console.log(err);
      });
    getEventTimings();
  }, [slug, dispatch, navigate, getEventTimings]);

  useEffect(() => {
    if (!bookDate) return;
    setTimings(scheduleTimings?.schedule_times?.[bookDate]);
  }, [scheduleTimings, bookDate]);

  const renderSteps = () => {
    // eslint-disable-next-line default-case
    switch (step) {
      case 1:
        return (
          <>
            <SubHeader eventData={eventData} />
            <ScheduleEvent
              setStep={setStep}
              timings={timings}
              eventData={eventData}
              setBookingData={setBookingData}
              bookingData={bookingData}
              bookDate={bookDate}
              scheduleTimings={scheduleTimings}
              setBookDate={setBookDate}
              currentTime={currentTime}
              currentTimeZone={currentTimeZone}
            />
          </>
        );
      case 2:
        return (
          <>
            <SubHeader eventData={eventData} />
            <DetailPage
              setStep={setStep}
              setBookingData={setBookingData}
              bookingData={bookingData}
              eventId={eventData.id}
            />
          </>
        );
      case 3:
        return (
          <ConfirmationPage
            eventData={eventData}
            bookingData={bookingData}
            currentTimeZone={currentTimeZone}
          />
        );
      case 4: 
        return(
          <ConfirmationPage
            eventData={eventData}
            bookingData={bookingData}
            currentTimeZone={currentTimeZone}
            alreadyScheduled={true}
          />
        )
    }
  };

  return (
    <div>
      <Header setStep={setStep} />
      <main>{renderSteps()}</main>
      <Footer></Footer>
    </div>
  );
};

export default App;
