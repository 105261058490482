import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./components/book-event";
import Meet from "./components/meet";
import moment from "moment-timezone";
import { useState, useEffect } from "react";
import { abbreviation } from "./shared/utils/abbreviation";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfUse from "./components/pages/TermsOfUse";

import ClientMeet from "./components/date-time/ClientMeet";
import GenerateMeetLink from "./components/date-time/GenerateMeetLink";

const App = () => {
  const [currentTime, setCurrentTime] = useState(
    moment().tz(moment.tz.guess()).format("hh:mm A")
  );
  const [currentTimeZone, setCurrentTimeZone] = useState("");

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(moment().tz(moment.tz.guess()).format("hh:mm A") || "");
    }, 1000);
    let filteredAbbreviation = abbreviation.filter((item, index) =>
      item.utc.includes(moment.tz.guess())
    );

    filteredAbbreviation.length > 0
      ? setCurrentTimeZone(filteredAbbreviation[0].value)
      : setCurrentTimeZone("");
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Index
              currentTime={currentTime}
              currentTimeZone={currentTimeZone}
            />
          }
          exact
        />
        <Route
          path="/meet/:slug"
          element={
            <Meet currentTime={currentTime} currentTimeZone={currentTimeZone} />
          }
          exact
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />

        <Route path="/terms-of-use" element={<TermsOfUse />} exact />
        <Route path="/client-meet" element={<ClientMeet />} exact />
        <Route path="/client-meet/:hash" element={<ClientMeet />} exact />
        <Route path="/schedule-meet/:hash" element={<ClientMeet />} exact />
        <Route path="/generate-meet-link" element={<GenerateMeetLink />} exact />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
