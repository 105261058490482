import React from "react";
import deleteIcon from "../../assets/images/delete.png";
import addIcon from "../../assets/images/add.png";
import { getTimings } from "../../shared/utils/helpers";
import moment from "moment";

const BookTimeList = ({
  addBookTimes,
  removeBookTimes,
  timings = null,
  dayItem = null,
  index = null,
  onTimeChange,
  commonSlotIndex = null,
  commonSlotItem = null,
  isDisabled = false,
}) => {
  return (
    <div className="md:flex flex-wrap gap-4 minMaxMd:gap-2 pb-6">
      <div className="form-select">
        <select
          name="start_time"
          value={timings?.start_time || commonSlotItem?.start_time || ""}
          onChange={(e) => onTimeChange(e, dayItem, index, commonSlotIndex)}
          disabled={isDisabled}
        >
          <option disabled value="">
            -- Select --
          </option>
          {getTimings().map((option, ind) => (
            <option key={ind} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <h4 className="flex items-center md:mx-3 lg:mx-6 maxMd:my-6 ">TO</h4>
      <div className="form-select">
        <select
          name="end_time"
          value={timings?.end_time || commonSlotItem?.end_time || ""}
          onChange={(e) => onTimeChange(e, dayItem, index, commonSlotIndex)}
          disabled={isDisabled}
        >
          <option disabled value="">
            -- Select --
          </option>
          {getTimings()
            .filter(
              (item, index) =>
                moment(item.value, "h:mma").isAfter(
                  moment(commonSlotItem?.start_time, "h:mma")
                ) ||
                moment(item.value, "h:mma").isAfter(
                  moment(timings?.start_time, "h:mma")
                )
            )
            .map((option, ind) => (
              <option key={ind} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      </div>
      <div className="flex maxMd:mt-8 book-this-event__icons">
        <button type="button" className="md:mx-4">
          <img
            src={deleteIcon}
            onClick={() => removeBookTimes(dayItem, index, commonSlotIndex)}
            alt="delete"
          />
        </button>
        <button type="button" className="maxMd: ml-6">
          <img src={addIcon} onClick={() => addBookTimes(dayItem)} alt="add" />
        </button>
      </div>
    </div>
  );
};

export default BookTimeList;
