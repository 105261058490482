import { isEmpty } from "lodash";

export default function validateFormData(formData) {
  let hasError = false;
  const errors = {};
  const emailAddressRegex = /\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  // Validate organizer_name
  if (isEmpty(formData.organizer_name.trim())) {
    errors["organizer_name"] = "Name is required";
    hasError = true;
  }

  // Validate organizer_email
  if (isEmpty(formData.organizer_email.trim())) {
    errors["organizer_email"] = "Email is required";
    hasError = true;
  } else if (!formData.organizer_email.match(emailAddressRegex)) {
    errors["organizer_email"] = "Please enter a valid email";
    hasError = true;
  }

  return { hasError, errors };
}
