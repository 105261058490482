import moment from "moment";
import zoomIcon from "../../assets/images/svg/zoom.svg";
import skypeIcon from "../../assets/images/svg/skype.svg";
import locationIcon from "../../assets/images/svg/location.svg";
import phoneIcon from "../../assets/images/svg/phone.svg";
import googleMeetIcon from "../../assets/images/svg/google_meet.svg";
import otherIcon from "../../assets/images/svg/other.svg";
import { CLIENT_MEET_TIME_INTERVAL, INDIAN_CLIENT_MEET_END_TIME, INDIAN_CLIENT_MEET_START_TIME } from "./constants";

export function getErrors(err) {
  let errors = err && err.response && err.response.data;
  errors = errors || {};
  const formattedErrors = {
    message: errors.message,
    fields: {},
  };

  for (let prop in errors.errors) {
    formattedErrors.fields[prop] = errors.errors[prop][0];
  }

  return formattedErrors;
}

export const dateFormat = (date) => {
  return new Date(
    moment(date).format("YYYY"),
    moment(date).format("M") - 1,
    moment(date).format("DD")
  );
};

export const getTimings = () => {
  let timeSlots = [];
  const fromTime = moment("00:00", ["HH:mm"]);
  const toTime = moment("23:59", ["HH:mm"]);

  while (fromTime < toTime) {
    timeSlots.push({
      label: fromTime.format("hh:mm A"),
      value: fromTime.format("HH:mm"),
    });
    fromTime.add(15, "m").format("HH:mm");
  }
  return timeSlots;
};

export const options = [
  {
    value: "In-person Meet",
    label: "In-person Meet",
    icon: locationIcon,
  },
  { value: "Phone Call", label: "Phone Call", icon: phoneIcon },
  { value: "Google Meet", label: "Google Meet", icon: googleMeetIcon },
  { value: "Zoom", label: "Zoom", icon: zoomIcon },
  { value: "Skype", label: "Skype", icon: skypeIcon },
  { value: "Other", label: "Other", icon: otherIcon },
];

export const icons = {
  Zoom: zoomIcon,
  Skype: skypeIcon,
  Other: otherIcon,
  "In-person Meet": locationIcon,
  "Phone Call": phoneIcon,
  "Google Meet": googleMeetIcon,
};

//client meet helper methods

export function formatDate(inputDate) {
  const formattedDate = moment(inputDate).format("dddd, MMMM DD, YYYY");
  return formattedDate;
}


export const getAvailableTimes = (clientMeetData) => {
  const currentTime = moment();
    const selectedDateMoment = moment(clientMeetData.schedule_date);
    const currentDate = moment(selectedDateMoment).format("YYYY-MM-DD");
    const startTime = moment.tz(
      `${currentDate} ${INDIAN_CLIENT_MEET_START_TIME}`,
      "Asia/Kolkata"
    );
    const endTime = moment
      .tz(`${currentDate} ${INDIAN_CLIENT_MEET_END_TIME}`, "Asia/Kolkata")
      .add(1, "minutes");
    const timeIntervals = [];

    while (startTime.isBefore(endTime)) {
      const convertedTime = startTime.clone().tz(clientMeetData.timezone);

      // Check if the selected date matches today's date
      if (selectedDateMoment.isSame(currentTime, "day")) {
        // If it's today and the time has already passed, skip it
        if (convertedTime.isAfter(currentTime)) {
          let check = convertedTime
            .clone()
            .add(clientMeetData.duration, "minutes")
            .isBefore(endTime);
          if (check) {
            timeIntervals.push(convertedTime.format("hh:mm A"));
          }
        }
      } else {
        let check = convertedTime
          .clone()
          .add(clientMeetData.duration, "minutes")
          .isBefore(endTime);
        if (check) {
          timeIntervals.push(convertedTime.format("hh:mm A"));
        }
      }

      startTime.add(CLIENT_MEET_TIME_INTERVAL, "minutes");
    }

    return timeIntervals;
}