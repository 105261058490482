import React, { useEffect, useState } from "react";
import ClientMeetStepNavigation from "./ClientMeetStepNavigation";
import ClientMeetFirstForm from "./ClientMeetFirstForm";
import ClientMeetSecondForm from "./ClientMeetSecondForm";
import moment from "moment";
import {
  CLIENT_MEET_TIME_INTERVAL,
  INDIAN_CLIENT_MEET_END_TIME,
  INDIAN_CLIENT_MEET_START_TIME,
} from "../../shared/utils/constants";
import ErrorSuccessModal from "./ErrorSuccessModal";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getClientMeetEvent } from "../../store/schedule/action";
import toast from "react-simple-toasts";

const ClientMeet = () => {
  const [step, setStep] = useState(1);
  const [clientMeetData, setClientMeetData] = useState({
    organizer_name: "",
    organizer_lastName: "",
    organizer_email: "",
    description: "",
    duration: "15",
    timezone: moment.tz.guess(),
    schedule_date: new Date(),
    schedule_timings: {
      startTime: "",
      endTime: "",
    },
  });

  const { hash } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!hash) return;
    dispatch(getClientMeetEvent(hash))
      .then((res) => {
        const response = res.data;
        setClientMeetData((prevState) => ({
          ...prevState,
          ...response
        }));
      })
      .catch((err) => {
        toast('Oops! unable to get Event Data! 😔')
        console.log(err);
      });
  }, [hash, dispatch]);

  const [isError, setIsError] = useState(true);

  const [formData, setFormData] = useState({
    formStep: null,
    timeZoneStartDate: "",
  });

  const [convertedTimes, setConvertedTimes] = useState([]);

  useEffect(() => {
    const currentDateTime = moment();
    const selectedDate = moment(clientMeetData.schedule_date);
    const currentDate = moment().format("YYYY-MM-DD");
    const startTime = moment.tz(
      `${currentDate} ${INDIAN_CLIENT_MEET_START_TIME}`,
      "Asia/Kolkata"
    );
    const endTime = moment
      .tz(`${currentDate} ${INDIAN_CLIENT_MEET_END_TIME}`, "Asia/Kolkata")
      .add(1, "minutes");
    const timeIntervals = [];

    while (startTime.isBefore(endTime)) {
      const convertedTime = startTime.clone().tz(clientMeetData.timezone);
      // Check if the selected date matches today's date
      if (selectedDate.isSame(currentDateTime, "day")) {
        // If it's today and the time has already passed, skip it
        if (convertedTime.isAfter(currentDateTime)) {
          let check = convertedTime
            .clone()
            .add(clientMeetData.duration, "minutes")
            .isBefore(endTime);
          if (check) {
            timeIntervals.push(convertedTime.format("hh:mm A"));
          }
        }
      } else {
        let check = convertedTime
          .clone()
          .add(clientMeetData.duration, "minutes")
          .isBefore(endTime);
        if (check) {
          timeIntervals.push(convertedTime.format("hh:mm A"));
        }
      }

      startTime.add(CLIENT_MEET_TIME_INTERVAL, "minutes");
    }

    setConvertedTimes(timeIntervals);
  }, [
    clientMeetData.timezone,
    clientMeetData.schedule_date,
    clientMeetData.duration,
  ]);

  useEffect(() => {
    const currentTimeZoneDateTime = moment()
      .tz(clientMeetData.timezone)
      .format("YYYY-MM-DD");
    setFormData((prev) => ({
      ...prev,
      timeZoneStartDate: new Date(currentTimeZoneDateTime),
    }));
  }, [clientMeetData.timezone]);

  return (
    <>
      <div className="main_container">
        <ClientMeetStepNavigation step={step} />
        {step === 1 && (
          <ClientMeetFirstForm
            setStep={setStep}
            setClientMeetData={setClientMeetData}
            clientMeetData={clientMeetData}
            convertedTimes={convertedTimes}
            formData={formData}
            setFormData={setFormData}
          />
        )}
        {step === 2 && (
          <ClientMeetSecondForm
            setStep={setStep}
            setClientMeetData={setClientMeetData}
            clientMeetData={clientMeetData}
            setIsError={setIsError}
          />
        )}
        {step === 3 && (
          <ErrorSuccessModal
            setStep={setStep}
            clientMeetData={clientMeetData}
            isError={isError}
          />
        )}
      </div>
    </>
  );
};

export default ClientMeet;
