import React from "react";
import ClockIcon from "../../../assets/images/svg/clock-icon.svg";
import { icons } from "../../../shared/utils/helpers";

const SubHeader = (props) => {
  let icon = props.eventData.location;
  return (
    <section className="sub-header">
      <div className="sub-header__info flex justify-between w-full">
        <div>
          <label>Meeting with</label>
          <h4 className="mb-5 mt-1">{props.eventData.organizer_name}</h4>
          <label>Event</label>
          <h2>{props.eventData.name}</h2>
        </div>
        <div className="sub-header__location flex items-start">
          <div className="mr-8">
            <label className="ml-10">Duration</label>
            <span className="flex items-center ">
              <img src={ClockIcon} alt="clock-icon" />
              <h3>{props.eventData.duration} min</h3>
            </span>
          </div>
          <div>
            <label className="ml-10">Location</label>
            <span className="flex items-center">
              {icons[icon] && <img src={icons[icon]} alt={`${icon}-icon`} />}
              <h3>{props.eventData.location}</h3>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SubHeader;
