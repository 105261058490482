import React from "react";

const ThemeSwitch = ({ label, onChange, defaultChecked }) => {
  return (
    <div className="switch">
      <span>{label}</span>
      <label>
        <input
          type="checkbox"
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        <div className="slider" />
      </label>
    </div>
  );
};

export default ThemeSwitch;
