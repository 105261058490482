import React from "react";
import classNames from "classnames";
import ThemeSwitch from "../theme-switch/ThemeSwitch";
import BookTimeList from "./BookTimeList";

const BookTime = ({
  switchActive,
  daySlot,
  setDaySlot,
  commonSlot,
  setCommonSlot,
  eventData,
  setEventData,
  setErrors,
  errors,
  setUnavailable,
  unavailable,
}) => {
  const timeSlot = { start_time: "", end_time: "" };
  const dayNames = {
    monday: "MON",
    tuesday: "TUE",
    wednesday: "WED",
    thursday: "THU",
    friday: "FRI",
    saturday: "SAT",
    sunday: "SUN",
  };

  const addBookTimes = (dayItem) => {
    if (!switchActive) {
      setCommonSlot([...commonSlot, timeSlot]);
    } else {
      daySlot[dayItem].push(timeSlot);
      setDaySlot({ ...daySlot });
    }
  };

  const removeBookTimes = (dayItem, index, commonSlotIndex) => {
    if (!switchActive) {
      if (commonSlot.length <= 1) {
        return;
      }
      commonSlot.splice(commonSlotIndex, 1);
      setCommonSlot([...commonSlot]);
      setEventData({ ...eventData, common_schedule_timings: commonSlot });
    } else {
      if (daySlot[dayItem].length <= 1) {
        return;
      }
      daySlot[dayItem].splice(index, 1);
      setDaySlot({ ...daySlot });
      setEventData({ ...eventData, schedule_timings: daySlot });
    }
  };

  const onTimeChange = (e, day, index, commonSlotIndex) => {
    if (!switchActive) {
      commonSlot[commonSlotIndex][e.target.name] = e.target.value;
      setCommonSlot([...commonSlot]);
      setEventData({ ...eventData, common_schedule_timings: commonSlot });
      if (errors?.["schedule_timings"]?.[commonSlotIndex]?.[e.target.name])
        errors["schedule_timings"][commonSlotIndex][e.target.name] = false;
    } else {
      daySlot[day][index][e.target.name] = e.target.value;
      setDaySlot({ ...daySlot });
      setEventData({ ...eventData, schedule_timings: daySlot });
      if (errors?.["schedule_timings"]?.[day]?.[index]?.[e.target.name])
        errors["schedule_timings"][day][index][e.target.name] = false;
    }
    setErrors(errors);
  };

  const changeUnavailable = (day) => {
    let check = unavailable.includes(day);

    if (!check) {
      setUnavailable([...unavailable, day]);
    } else {
      const dayIndex = unavailable.indexOf(day);
      if (dayIndex > -1) {
        setUnavailable(unavailable.filter((_, index) => index !== dayIndex));
      }
    }
  };

  return (
    <>
      {!switchActive && (
        <ul>
          {commonSlot.map((commonSlotItem, commonSlotIndex) => (
            <li key={commonSlotIndex}>
              <BookTimeList
                addBookTimes={addBookTimes}
                removeBookTimes={removeBookTimes}
                onTimeChange={onTimeChange}
                commonSlotIndex={commonSlotIndex}
                commonSlotItem={commonSlotItem}
              />
              {errors?.schedule_timings?.[commonSlotIndex]?.time && (
                <span className="text-danger">
                  {errors?.schedule_timings?.[commonSlotIndex]?.time}
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
      {switchActive && (
        <ul>
          {Object.keys(daySlot).map((dayItem, dayIndex) => (
            <li key={dayIndex}>
              <div
                className={classNames(
                  switchActive ? "calender-wrap" : "hidden"
                )}
              >
                <div className="calender">
                  <ul>
                    <li className="active"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  <span>{dayNames?.[dayItem]}</span>
                </div>
                <div className="text-silverfoil ml-4">Unavailable</div>
                <div className="relative set-time-switch">
                  <ThemeSwitch
                    onChange={() => changeUnavailable(dayItem)}
                    defaultChecked={unavailable.includes(dayItem)}
                  />
                </div>
              </div>
              {Object.keys(daySlot[dayItem]).map((item, index) => (
                <div key={index}>
                  <BookTimeList
                    key={index}
                    addBookTimes={addBookTimes}
                    removeBookTimes={removeBookTimes}
                    switchActive={switchActive}
                    dayItem={dayItem}
                    index={index}
                    timings={daySlot[dayItem][index]}
                    onTimeChange={onTimeChange}
                    isDisabled={unavailable.includes(dayItem)}
                  />
                  {errors?.schedule_timings?.[dayItem]?.[index]?.time && (
                    <span className="text-danger">
                      {errors?.schedule_timings?.[dayItem]?.[index]?.time}
                    </span>
                  )}
                </div>
              ))}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default BookTime;
