import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import ThemeSwitch from "../theme-switch/ThemeSwitch";
import logo from "../../assets/images/svg/logo.svg";
import { ReactComponent as DarkThem } from "../../assets/images/svg/dark-theme.svg";
import { ReactComponent as LightThem } from "../../assets/images/svg/light-theme.svg";

const Header = ({ setStep }) => {
  let navigate = useNavigate();
  const [checked, setChecked] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );

  useEffect(() => {
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
  }, []);
  const toggleThemeChange = () => {
    if (checked === false) {
      localStorage.setItem("theme", "dark");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setChecked(true);
    } else {
      localStorage.setItem("theme", "light");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setChecked(false);
    }
  };

  return (
    <div className="header">
      <div className="header-container">
        <div className="header__logo">
          <img
            src={logo}
            alt="logo"
            className="cursor-pointer"
            onClick={()=> ((setStep !== null) ? setStep(1) : navigate("/", {replace : true}))}
          />
        </div>
        <LightThem />
        <DarkThem />
        <ThemeSwitch defaultChecked={checked} onChange={toggleThemeChange} />
      </div>
    </div>
  );
};

export default Header;
