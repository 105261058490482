import React from "react";
import AllTimeZones from "./AllTimeZones";
import ReactSelect from "react-select";

const Dropdown = ({ setClientMeetData, setShowTimeZoneDropdown }) => {
  const options = AllTimeZones();

  const onsearchChange = (e) => {
    setShowTimeZoneDropdown(false);
    setClientMeetData((prev) => ({
      ...prev,
      timezone: e?.value,
    }));
  };

  return (
    <div className="dropdown_container">
      {/* <div className="pointer_arrow"></div> */}
      <div className="search_control">
        <ReactSelect
          options={options}
          menuIsOpen
          onChange={onsearchChange}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </div>
  );
};

export default Dropdown;
