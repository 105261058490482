import React, { useState, useRef } from "react";
import Validations from "./Validation";
import { useDispatch } from "react-redux";
import { storeBooking } from "../../../store/schedule/action";
import moment from "moment-timezone";

const DetailPage = ({ setStep, setBookingData, bookingData, eventId }) => {
  const [errors, setErrors] = useState({});
  const isSubmitted = useRef(false);
  const [isBooking, setIsBooking] = useState(false);
  const dispatch = useDispatch();

  const onChange = (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });

    if (isSubmitted.current) {
      checkForErrors(bookingData);
    }
  };

  const checkForErrors = (data) => {
    const { errors, hasError } = Validations(data);
    setErrors(errors);
    return hasError;
  };

  const onNext = (e) => {
    e.preventDefault();
    isSubmitted.current = true;

    const hasError = checkForErrors(bookingData);

    if (!hasError) {
      setIsBooking(true);
      bookingData.timezone = moment.tz.guess();
      dispatch(storeBooking(eventId, bookingData))
        .then((res) => {
          setBookingData({ ...bookingData, timezone: res.data.data.timezone });
          setIsBooking(false);
          setStep(3);
        })
        .catch((err) => {
          setIsBooking(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <section className="schedule-event">
        <div className="schedule-event__title">Enter your details</div>
        <form onSubmit={onNext}>
          <div className="grid sm:grid-cols-2 sm:gap-3 mt-8">
            <div className="form-group">
              <label>
                Your name<span>*</span>
              </label>
              <input className="form-control" name="name" onChange={onChange} />
              {errors.name && (
                <span className="text-danger">{errors.name}</span>
              )}
            </div>
          </div>
          <div className="grid sm:grid-cols-2 sm:gap-3">
            <div className="form-group">
              <label>
                Your email<span>*</span>
              </label>
              <input
                className="form-control"
                name="email"
                onChange={onChange}
              />
              {errors.email && (
                <span className="text-danger">{errors.email}</span>
              )}
            </div>
          </div>
          <div className="form-group items-end sm:mt-16 mt-10">
            <button type="submit" className="btn btn-dark" disabled={isBooking}>
              Schedule Event
              {isBooking && <div className="loader"></div>}
            </button>
          </div>
        </form>
      </section>
    </>
  );
};
export default DetailPage;
