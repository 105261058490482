import React, { useState } from "react";
import validateFormData from "./Validations";
import { useDispatch } from "react-redux";
import { generateMeetLink } from "../../store/schedule/action";
import { ReactComponent as GlobIcon } from "../../assets/images/svg/globe.svg";
import { ReactComponent as CopyIcon } from "../../assets/images/svg/copy-icon.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import arrowIcon from "../../assets/images/arrow.png";
import logo from "../../assets/images/svg/calendar-icon-large.svg";

const GenerateMeetLink = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [url, setUrl] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();

  const [generateMeetLinkData, setGenerateMeetLinkData] = useState({
    organizer_name: "",
    organizer_email: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGenerateMeetLinkData(() => ({
      ...generateMeetLinkData,
      [name]: value,
    }));
  };

  const checkForErrors = (data) => {
    const { errors, hasError } = validateFormData(data);
    setErrors(errors);
    return hasError;
  };

  const resetForm = () => {
    setGenerateMeetLinkData((prev) => ({
      ...prev,
      organizer_name: "",
      organizer_email: "",
      description: "",
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const hasError = checkForErrors(generateMeetLinkData);

    if (!hasError) {
      setIsLoading(true);
      dispatch(generateMeetLink(generateMeetLinkData))
        .then((res) => {
          setUrl(res.data.client_meet_link);
          resetForm();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const copyUrl = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 500);
  };

  return (
    <>
      {!url && (
        <div className="flex items-center mt-6 justify-center gap-4">
          <img src={logo} className="logo_image" alt="logo" />
          <p className="text-2xl">Generate a Meeting link</p>
        </div>
      )}
      {url && (
        <div className="confirmation__copy-link flex mx-auto max-w-850">
          <div className="flex items-center gap-4">
            <img src={logo} className="logo_image" alt="logo" />
            <div className="form-control relative">
              <GlobIcon width="20" height="20" className="mr-2" />
              {isClicked && (
                <div className="tooltip">
                  <span className="tooltiptext">Copied</span>
                </div>
              )}
              <span> {url} </span>
              <CopyToClipboard text={url}>
                <button
                  className="ml-3 hover:text-black-dark"
                  onClick={copyUrl}
                  disabled={isClicked}
                >
                  <CopyIcon />
                </button>
              </CopyToClipboard>
            </div>
          </div>
          <button className="btn btn-dark" style={{ display: "none" }}>
            <img src={arrowIcon} alt="arrow-icon" />
          </button>
        </div>
      )}
      <form onSubmit={handleOnSubmit}>
        <div className="form_container">
          <div className="form_top">
            <h3>Client Information</h3>

            <div className=" mt-6 relative text_field">
              <div className="text_form w-full">
                <p className="label">Name</p>
                <input
                  type="text"
                  name="organizer_name"
                  onChange={handleInputChange}
                  value={generateMeetLinkData.organizer_name}
                />
                <span className="error">{errors.organizer_name}</span>
              </div>
            </div>
            <div className=" mt-6 relative text_field">
              <div className="text_form w-full">
                <p className="label">Your email address</p>
                <input
                  type="text"
                  name="organizer_email"
                  onChange={handleInputChange}
                  value={generateMeetLinkData.organizer_email}
                />
                <span className="error">{errors.organizer_email}</span>
              </div>
            </div>
            <div className="mt-6 text_field">
              <div className="text_form w-full relative">
                <p className="label">Description</p>
                <textarea
                  className="w-full"
                  name="description"
                  onChange={handleInputChange}
                  value={generateMeetLinkData.description}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="bottom_navigation">
            <button
              type="submit"
              className="flex justify-center items-center gap-1"
              disabled={isLoading}
            >
              {isLoading && <div className="loader"></div>}
              Generate Meet Link
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default GenerateMeetLink;
