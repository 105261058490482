import WhatEventIsThat from "./what-event-is-that/WhatEventIsThat";
import Header from "../header/Header";
import BookThisEvent from "./book-this-event/BookThisEvent";
import { useState } from "react";
import Confirmation from "./confirmation/Confirmation";
import SubmitBookEvent from "./submit-book-time/SubmitBookTime";
import { toastConfig } from "react-simple-toasts";
import Footer from "../footer/footer";

toastConfig({
  position: "right",
});

const App = ({ currentTime, currentTimeZone }) => {
  const [step, setStep] = useState(1);

  const [eventData, setEventData] = useState({
    name: "",
    organizer_name: "",
    organizer_email: "",
    location: "",
    description: "",
    duration: "",
  });
  const [icon, setIcon] = useState(null);
  const [meetingData, setMeetingData] = useState({});

  const renderSteps = () => {
    // eslint-disable-next-line default-case
    switch (step) {
      case 1:
        return (
          <WhatEventIsThat
            setStep={setStep}
            eventData={eventData}
            setEventData={setEventData}
            icon={icon}
            setIcon={setIcon}
          />
        );
      case 2:
        return (
          <BookThisEvent
            setStep={setStep}
            eventData={eventData}
            setEventData={setEventData}
            setMeetingData={setMeetingData}
            icon={icon}
            setIcon={setIcon}
            currentTime={currentTime}
            currentTimeZone={currentTimeZone}
          />
        );
      case 3:
        return (
          <Confirmation
            setStep={setStep}
            eventData={eventData}
            meetingData={meetingData}
            icon={icon}
            setIcon={setIcon}
          />
        );
      case 4:
        return <SubmitBookEvent eventData={eventData} />;
    }
  };

  return (
    <div>
      <Header setStep={setStep} />
      <main>{renderSteps()}</main>
      <Footer></Footer>
    </div>
  );
};

export default App;
