import React, { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import moment from "moment";
import Validations from "./Validations";
import { dateFormat } from "../../../shared/utils/helpers";
import BookTimeList from "./BookTimeList";
import { ReactComponent as GlobIcon } from "../../../assets/images/svg/globe.svg";

const ScheduleEvent = ({
  setStep,
  eventData,
  timings,
  setBookingData,
  bookingData,
  scheduleTimings,
  bookDate,
  setBookDate,
  currentTime,
  currentTimeZone,
}) => {
  const [bookTime, setBookTime] = useState(null);
  const [errors, setErrors] = useState({});
  const [calenderValue, setCalenderValue] = useState(null);

  const onDateChange = (e) => {
    setCalenderValue(e);
    const selectedDate = moment(e).format("YYYY-MM-DD");
    setBookDate(selectedDate);
  };

  const onTimeChange = (e, time) => {
    setBookTime(moment(time, ["hh:mm a"]).format("HH:mm:ss"));
  };

  const checkForErrors = (date, time) => {
    const { errors, hasError } = Validations(date, time);
    setErrors(errors);
    return hasError;
  };

  const onNext = (e) => {
    e.preventDefault();
    const hasError = checkForErrors(bookDate, bookTime);
    if (hasError) {
      return;
    }
    let bookedAt = bookDate + " " + bookTime;
    setBookingData({
      ...bookingData,
      booked_at: bookedAt,
    });

    setStep(2);
  };

  const selectedDate = (calenderValue) => {
    return calenderValue || bookDate ? dateFormat(bookDate) : null;
  };

  const minDate = () => {
    return dateFormat(Object.keys(scheduleTimings?.schedule_times)[0]);
  };

  const maxDate = () => {
    const scheduleTimingDates = Object.keys(scheduleTimings?.schedule_times);
    return dateFormat(scheduleTimingDates[scheduleTimingDates.length - 1]);
  };

  const getDisabledDates = () => {
    return scheduleTimings?.unavailable?.map((item, key) => dateFormat(item));
  };

  let showMonthArrow = true;
  if (minDate().getMonth() === maxDate().getMonth()) {
    showMonthArrow = false;
  }

  useEffect(() => {
    const eventStartDate = Object.keys(scheduleTimings?.schedule_times)[0];
    if (eventStartDate) {
      setBookDate(eventStartDate);
    }
  }, [scheduleTimings, setBookDate]);

  return (
    <>
      <section className="schedule-meeting">
        <div className="schedule-meeting__heading">Select a Date & Time</div>
        <div className="calendar">
          <Calendar
            date={selectedDate(calenderValue)}
            onChange={(e) => onDateChange(e)}
            minDate={minDate()}
            maxDate={maxDate()}
            disabledDates={getDisabledDates()}
            showMonthArrow={showMonthArrow}
          />
        </div>
        {errors.date && <span className="text-danger">{errors.date}</span>}
        {currentTime && currentTimeZone && (
          <div className="time-zone mt-6">
            <label className="text-lg font-bold">Timezone</label>
            <span className="flex items-center mt-2">
              <GlobIcon width="20" height="20" />
              {currentTime !== "" && currentTimeZone !== "" && (
                <p className="ml-2">
                  {currentTimeZone + " (" + currentTime + ")"}
                </p>
              )}
            </span>
          </div>
        )}
        {timings &&
          Object.keys(timings).map(
            (item, index) =>
              timings[item].length > 0 && (
                <div key={index}>
                  <BookTimeList
                    item={item}
                    timings={timings[item]}
                    onTimeChange={onTimeChange}
                    bookTime={bookTime}
                  />
                </div>
              )
          )}

        {errors.time && <span className="text-danger">{errors.time}</span>}
        <div className="form-group items-end sm:mt-16 mt-10 mr-8">
          <button
            type="submit"
            className="btn btn-dark"
            onClick={(e) => onNext(e)}
          >
            Next
          </button>
        </div>
      </section>
    </>
  );
};
export default ScheduleEvent;
