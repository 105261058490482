import moment from "moment-timezone";
const AllTimeZones = () => {
  const timezones = moment.tz.names();
  const timezoneGroups = {};

  timezones.forEach((tz) => {
    const offset = moment.tz(tz).utcOffset() / 60;
    const offsetString = offset >= 0 ? `+${offset}` : offset.toString();
    const cityName = tz?.split("/")[1]?.replace(/_/g, " ");
    const optionLabel = `UTC ${offsetString}:00 ${cityName}`;
    const currentTime = moment().tz(tz).format("YYYY-MM-DD HH:mm");
    const time = `${currentTime}, ${tz}`;
    if (!timezoneGroups[offsetString]) {
      timezoneGroups[offsetString] = [];
    }
    var pattern = /undefined/;

    if (pattern.test(optionLabel)) {
      return;
    } else {
      timezoneGroups[offsetString].push({
        value: tz,
        label: optionLabel,
        time: time,
      });
    }
  });

  const options = Object.entries(timezoneGroups).map(([offset, group]) => ({
    label: `UTC ${offset}:00`,
    options: group,
  }));
  return options;
};
export default AllTimeZones;
